<template>
  <div class="aboutOurCompany">
    <div class="title"><h3>ركن الاطفال</h3></div>
    <v-divider></v-divider>
    <video-app></video-app>
  </div>
</template>
<script>
import VideoApp from "../../components/video.vue";
export default {
  components: {
    VideoApp,
  },
};
</script>
<style scoped>
</style>